import React, { useState } from "react"
import Layout from "../../components/Layout"
import {
  ContactForm,
  Max,
  SubmitButton,
} from "../../components/contact/contact"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import styled from "styled-components"
import { navigate } from "gatsby"
import breakpoints from "../../constants/breakpoints"
import dimensions from "../../constants/dimensions"

const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS"
const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR"
const MAIL_SENDING_SUCCESS = "MAIL_SENDING_SUCCESS"
const MAIL_SENDING_ERROR = "MAIL_SENDING_ERROR"

const Introduction = styled.section`
  display: flex;
  flex-direction: column;
  margin: 1rem auto 0rem auto;
  max-width: ${dimensions.maxWidth}px;

  @media (max-width: 1080px) {
    width: 90%;
  }
`

const InputField = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
`

const LeftCol = styled.div`
  width: 25%;
  min-width: 260px;
  margin-top: 1rem;
  font-weight: bold;
`

const RightCol = styled.div`
  width: 75%;
  margin-top: 6px;
  display: flex;

  @media (max-width: ${breakpoints.small}px) {
    width: 90%;
  }
`

const RowWrapper = styled.div`
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }
`

export default function ContactPage() {
  const [state, setState] = useState({
    group: "",
    stamm: "",
    participants: 0,
    territory: "",
    contactperson: "",
    mail: "",
    checkbox: false,

    mailSent: false,
    // snackbar
    snackOpen: false,
    snackMessage: "",
  })

  function handleInputChange(event) {
    event.persist();
    setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSubmit = event => {
    event.preventDefault()

    // check if all fields are filled
    if (
      !state.group ||
      !state.stamm ||
      !state.participants ||
      !state.territory ||
      !state.contactperson ||
      !state.mail
    ) {
      setState(prevState => ({
        ...prevState,
        snackMessage: "Bitte fülle alle Felder aus",
        snackOpen: true,
      }))
    }

    if (!state.checkbox) {
      setState(prevState => ({
        ...prevState,
        snackMessage:
          "Du musst der Veröffentlichung des Berichts und der Bilder zustimmen, um fortzufahren.",

        snackOpen: true,
      }))
    }

    // create formData element holding the input from the contact form
    const formData = new FormData()
    formData.append("group", state.group)
    formData.append("stamm", state.stamm)
    formData.append("participants", state.participants)
    formData.append("territory", state.territory)
    formData.append("contactperson", state.contactperson)
    formData.append("mail", state.mail)

    const groupName = state.group

    const createGroupFolderPromise = new Promise((resolve, reject) => {
      const CREATE_FOLDER_PATH =
        "https://altburgund.de/cloud/remote.php/dav/files/bof/uploads/" +
        groupName

      fetch(CREATE_FOLDER_PATH, {
        method: "MKCOL",
        headers: {
          "OCS-APIRequest": "true",
          "Authorization": "Basic Ym9mOlA3bUtjJUEw", // + btoa('bof:P7mKc%A0')
        },
      }).then(result => {
        console.log(result)
        if (result.status === 201) {
          resolve("CREATE_FOLDER_SUCCESS")
        } else if (result.status === 401) {
          setState(prevState => ({
            ...prevState,
            snackMessage:
              "Der Bericht konnte nicht gesendet werden. Bitte versuche es mit Chrome erneut.",

            snackOpen: true,
          }))
          reject("CREATE_FOLDER_ERROR")
        } else {
          setState(prevState => ({
            ...prevState,
            snackMessage:
              "Etwas ist schief gelaufen. Wahrscheinlich existiert der Gruppenname bereits.",

            snackOpen: true,
          }))
          reject("CREATE_FOLDER_ERROR")
        }
      })
    })

    createGroupFolderPromise.then(result => {
      console.log("create group folder result: ", result)
      const sendMailPromise = new Promise((resolve, reject) => {
        // API for sending Mails
        const API_PATH = "https://altburgund.de/api/berichtUpload.php"

        fetch(API_PATH, {
          method: "post",
          body: formData,
        })
          .then(result => {
            console.log(result)
            if (result.status === 200) {
              resolve(MAIL_SENDING_SUCCESS)
            } else {
              reject(MAIL_SENDING_ERROR)
            }
          })
          .catch(err => {
            console.error(err)
            setState(prevState => ({
              ...prevState,
              snackMessage:
                "Etwas ist schiefgelaufen. Bitte sende uns stattdessen eine E-Mail an webmaster@altburgund.de",
              snackOpen: true,
            }))
          })
      })

      /* File upload to nextcloud */
      const fileUploadPromise = new Promise((resolve, reject) => {
        // create folder for group

        const file = document.getElementById("fileInput").files[0]
        // let report be sent without file too
        if (!file) return resolve(FILE_UPLOAD_SUCCESS)

        const filename = file.name

        const FILE_UPLOAD_PATH =
          "https://altburgund.de/cloud/remote.php/dav/files/bof/uploads/" +
          groupName +
          "/" +
          filename

        fetch(FILE_UPLOAD_PATH, {
          method: "PUT",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            "OCS-APIRequest": "true",
            "Authorization":
              "Basic Ym9mOnlxZW5rLTZtemlnLWZvUG1tLVJnelRELXJlQkFy", // + btoa('bof:P7mKc%A0')
          },
          body: file,
        }).then(result => {
          console.log(result)
          if (result.status === 201) {
            resolve(FILE_UPLOAD_SUCCESS)
          } else {
            console.log("error in file upload promise: ", result)
            reject(FILE_UPLOAD_ERROR)
          }
        })
      })

      Promise.all(
        [sendMailPromise, fileUploadPromise].map(promise =>
          promise.catch(e => e)
        )
      )
        .then(results => {
          /* no error */
          console.log("promise all results", results)
          if (
            results[0] === MAIL_SENDING_SUCCESS &&
            results[1] === FILE_UPLOAD_SUCCESS
          ) {
            setState(prevState => ({
              ...prevState,
              group: "",
              stamm: "",
              participants: 0,
              territory: "",
              contactperson: "",
              mail: "",
              checkbox: false,
              snackMessage: "Bericht erfolgreich hochgeladen",
              snackOpen: true,
            }))
            return true
          } else {
            setState(prevState => ({
              ...prevState,
              snackMessage:
                "Etwas ist schiefgelaufen. Bitte sende uns stattdessen eine E-Mail an webmaster@altburgund.de",
              snackOpen: true,
            }))
            return false
          }
        })
        .then(result => {
          console.log(result)
          if (!result) return
          console.log("promise all results", result)
          /* Create share */
          const SHARE_PATH =
            "https://altburgund.de/cloud/ocs/v2.php/apps/files_sharing/api/v1/shares?path=/uploads/gve&shareType=3&publicUpload=true"

          let prepare_post = {
            path: "/uploads/" + groupName,
            shareType: 3,
            publicUpload: true,
          }

          let property
          let formBody = []
          for (property in prepare_post) {
            let encodedKey = encodeURIComponent(property)
            let encodedValue = encodeURIComponent(prepare_post[property])
            formBody.push(encodedKey + "=" + encodedValue)
          }
          formBody = formBody.join("&")

          fetch(SHARE_PATH, {
            method: "post",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
              "OCS-APIRequest": "true",
              "Authorization":
                "Basic Ym9mOnlxZW5rLTZtemlnLWZvUG1tLVJnelRELXJlQkFy", // + btoa('bof:P7mKc%A0')
            },
            body: formBody,
          })
            //         .then(r => r.json())
            .then(r => r.text())
            .then(str =>
              new window.DOMParser().parseFromString(str, "text/xml")
            )
            .then(data => {
              const shareLink = data.getElementsByTagName("url")[0].innerHTML
              console.log("shareLink: ", shareLink)
              console.log(data.getElementsByTagName("url"))
              navigate("/bachelor-of-fahrt/success", {
                state: { shareLink },
              })
            })
        })
    })
  }

  // close snackbar
  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setState(prevState => ({
      ...prevState,
      snackOpen: false,
    }))
  }

  return (
    <Layout title="Gau Alt-Burgund | Fahrtenbericht">
      <Max>
        <Introduction>
          <br />
          <h1>Bericht einreichen</h1>
          <p>
            Hier kannst du deinen Fahrtenbericht einreichen. Fülle die folgenden
            Felder vollständig aus und lade deinen Fahrtenbericht (als PDF oder
            Word-Datei). Klicke dann auf "Senden". Danach hast du noch die
            Möglichkeit über einen Upload-Link weitere Bilder und Dateien
            hochzuladen.
          </p>
          <p>Der Upload funktioniert aktuell nur mit Chrome.</p>
        </Introduction>
        <ContactForm>
          <form onSubmit={handleSubmit}>
            <RowWrapper>
              <LeftCol>
                <label htmlFor="group">Gruppe/Sippe</label>
              </LeftCol>
              <RightCol>
                <InputField
                  type="text"
                  name="group"
                  value={state.group}
                  onChange={event => {
                    handleInputChange(event)
                  }}
                />
              </RightCol>
            </RowWrapper>

            <RowWrapper>
              <LeftCol>
                <label htmlFor="stamm">Stamm/Siedlung</label>
              </LeftCol>
              <RightCol>
                <InputField
                  type="text"
                  name="stamm"
                  value={state.stamm}
                  onChange={event => {
                    handleInputChange(event)
                  }}
                />
              </RightCol>
            </RowWrapper>

            <RowWrapper>
              <LeftCol>
                <label htmlFor="participants">Anzahl Fahrtenteilnehmer</label>
              </LeftCol>
              <RightCol>
                <InputField
                  type="number"
                  name="participants"
                  value={state.participants}
                  onChange={event => {
                    handleInputChange(event)
                  }}
                />
              </RightCol>
            </RowWrapper>

            <RowWrapper>
              <LeftCol>
                <label htmlFor="territory">Fahrtengebiet (Start- / Ziel)</label>
              </LeftCol>
              <RightCol>
                <InputField
                  type="text"
                  name="territory"
                  value={state.territory}
                  onChange={event => {
                    handleInputChange(event)
                  }}
                />
              </RightCol>
            </RowWrapper>

            <RowWrapper>
              <LeftCol>
                <label htmlFor="contactperson">
                  Kontaktperson/Sippenführer
                </label>
              </LeftCol>
              <RightCol>
                <InputField
                  type="text"
                  name="contactperson"
                  value={state.contactperson}
                  onChange={event => {
                    handleInputChange(event)
                  }}
                />
              </RightCol>
            </RowWrapper>

            <RowWrapper>
              <LeftCol>
                <label htmlFor="contactperson">E-Mail Adresse</label>
              </LeftCol>
              <RightCol>
                <InputField
                  type="email"
                  name="mail"
                  value={state.mail}
                  onChange={event => {
                    handleInputChange(event)
                  }}
                />
              </RightCol>
            </RowWrapper>

            <br />
            <br />
            <br />

            <RowWrapper>
              <LeftCol>
                <label htmlFor="files">Fahrtenbericht</label>
              </LeftCol>
              <RightCol>
                <InputField type="file" id={"fileInput"} />
              </RightCol>
            </RowWrapper>

            <br />

            <RowWrapper>
              <input
                type={"checkbox"}
                value={state.checkbox}
                name="checkbox"
                onChange={event => {
                  handleInputChange(event)
                }}
              />
              <span style={{ marginLeft: "1rem" }}>
                Ich stimme einer eventuellen Veröffentlichung des Berichts und
                der Bilder zu.
              </span>
            </RowWrapper>

            <RowWrapper>
              <LeftCol />
              <RightCol>
                <SubmitButton type="submit">
                  <span>Senden</span>
                </SubmitButton>
              </RightCol>
            </RowWrapper>
          </form>
        </ContactForm>
      </Max>
      <Snackbar
        style={{
          zIndex: "99999999",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={state.snackOpen}
        autoHideDuration={4000}
        onClose={handleSnackClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{state.snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleSnackClose}
            href={""}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Layout>
  )
}
